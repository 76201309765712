<template>
    <div class="secondary-sidebar">
        <div class="secondary-sidebar-heading gutter-10" :class="{'hamburger-button-active' : showMenu}" @click="showMenu = !showMenu">
            <div class="section-title text-capitalize">{{ $t("Settings") }}</div>
        </div>
        <div class="secondary-sidebar-body gutter-10" :class="{'hamburger-menu-show': showMenu}">
            <ul class="settings__nav">
                <li class="settings__nav__item" v-if="canCompanySettings">
                    <router-link
                            :to="{name: 'company.setting.basic'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("basic information")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canCompanySettings">
                    <router-link :to="{name: 'company.setting.photo'}" class="settings__nav__link text-capitalize text-capitalize"
                            exact exact-active-class="active"> {{ $t("photos & colors") }}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canCompanySettings">
                    <router-link :to="{name: 'company.setting.templates'}" class="settings__nav__link text-capitalize text-capitalize"
                                 exact exact-active-class="active"> {{ $t("Templates") }}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canTeamSettings">
                    <router-link
                            :to="{name: 'company.setting.user'}"
                            class="settings__nav__link text-capitalize"
                            exact :class="{'active': isActiveMatchMenu('/company/setting/user') }"
                    >{{ $t("Manage Users")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canOtherSettings">
                    <router-link
                            :to="{name: 'company.setting.app-key'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("App Key")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canOtherSettings">
                    <router-link
                            :to="{name: 'company.setting.custom-domain'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("Custom Domain")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canPipelineSettings">
                    <router-link
                            :to="{name: 'company.setting.pipeline'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("pipeline setup")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canEmailSettings">
                    <router-link
                            :to="{name: 'company.setting.email'}"
                            class="settings__nav__link text-capitalize"
                            exact :class="{'active': isActiveMatchMenu('/company/setting/email') }"
                    >{{ $t("email setup")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canOtherSettings">
                    <router-link
                            :to="{name: 'company.setting.integration'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("integrations")}}
                    </router-link>
                </li>
                <li class="settings__nav__item d-flex align-items-center" v-if="hasAIPermission && canOtherSettings">
                    <router-link
                            :to="{name: 'company.setting.ai-setup'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("ai setup")}}
                    </router-link>
                    <span class="primary-label label-small ml-1">{{$t('beta')}}</span>
                </li>
                <li class="settings__nav__item d-flex align-items-center" v-if="!isVerified && canOtherSettings">
                    <router-link
                            :to="{name: 'company.setting.verification'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("verification")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canApplyCandidateSettings">
                    <router-link
                            :to="{name: 'company.setting.apply-setting'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("Candidate Apply Setting")}}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canCompanySettings">
                    <router-link
                            :to="{name: 'company.setting.category-skill'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("Categories & skills")}}
                    </router-link>
                </li>
                <li class="settings__nav__item">
                    <router-link
                            :to="{name: 'company.setting.notifications'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("Notifications") }}
                    </router-link>
                </li>
                <li class="settings__nav__item" v-if="canCompanySettings">
                    <router-link
                            :to="{name: 'company.setting.activity-log'}"
                            class="settings__nav__link text-capitalize"
                            exact exact-active-class="active"
                    >{{ $t("Activity Log")}}
                    </router-link>
                </li>

            </ul>
            <a href="#" v-if="companyDeletePermission" @click="deleteCompany()" class="button danger-button w-100 text-capitalize">{{ $t("Delete Company")}}</a>
        </div>
    </div>
</template>
<script>
import client from "../../app/api/Client";
import {mapActions, mapState} from 'vuex';
import {EventBus} from "../../extra/event-bus";
import {SWITCH_TO_ACCOUNT} from '../../constants/action-type';
import {SWITCHED_TO_ANOTHER_ACCOUNT} from "../../constants/events";
import {
    canApplyCandidateSettings,
    canCompanySettings, canEmailSettings,
    canOtherSettings, canPipelineSettings,
    canTeamSettings
} from "../../config/permission";

export default {
        data() {
            return {
                showMenu: false
            }
        },
        computed: {
            ...mapState(['user', 'company']),
            hasAIPermission(){
                return this.user.package_rules.easyjobs_ai;
            },
            isVerified(){
                return this.company.is_verified;
            },
            companyDeletePermission() {
                return this.company.canDeleteCompany ?? false;
            },

            canCompanySettings() {
                return canCompanySettings();
            },
            canTeamSettings() {
                return canTeamSettings();
            },
            canPipelineSettings() {
                return canPipelineSettings();
            },
            canEmailSettings() {
                return canEmailSettings();
            },
            canApplyCandidateSettings() {
                return canApplyCandidateSettings();
            },
            canOtherSettings() {
                return canOtherSettings();
            }
        },
        methods: {
            ...mapActions([SWITCH_TO_ACCOUNT]),
            deleteCompany() {
                let message = {
                    title: this.$t("Confirmation"),
                    body: `<div class="custom-delete">
                                <p class="text-center">${this.$t('If you delete this company, following things will also be permanently deleted:')}</p>
                                <ul class="small-text text-left" style="font-size: 13px;">
                                    <li>-${this.$t('Company information with logo and images.')}</li>
                                    <li>-${this.$t('All contributors/users.')}</li>
                                    <li>-${this.$t('All published, draft & archived jobs.')}</li>
                                    <li>-${this.$t('All question set question & quizzes.')}</li>
                                    <li>-${this.$t('All candidates.')}</li>
                                    <li>-${this.$t('All pipelines.')}</li>
                                </ul>
                         </div>`
                };
                this.$dialog.confirm(message, {
                    html: true,
                    verification: 'delete',
                    type: 'hard',
                    okText: this.$t('yes'),
                    cancelText: this.$t('no')
                }).then(() => {
                    client().delete(`company/delete`)
                        .then(({data: {data, message}}) => {
                            this.$toast.success(message);
                            this.changeAccount();
                        }).catch(({response: {data}}) => {
                        this.$toast.error(data.message);
                    });
                })
            },
            changeAccount() {
                this.user.companies = this.user.companies.filter((company) => company.id !== this.company.id);
                this.company = null;
                let accountType = "user";
                this[SWITCH_TO_ACCOUNT](null);
                EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, accountType);
                this.$router.push({
                    name: 'dashboard'
                })
            },

            hideMenu() {
                this.showMenu = false;
            },
            isActiveMatchMenu(pathMatchPart) {
                return this.$route.path.startsWith(pathMatchPart);
            }
        }
    }
</script>

<style scoped>
    .dg-content {
        text-align: left !important;
    }
    .dg-content-cont--floating {
        top:40%!important;
        transform: translateY(-50%);

    }
    .small-text {
        font-size: 13px;
    }

    .primary-label.label-small {
        padding: 5px 10px;
        line-height: 1;
        font-size: .825rem;
    }
    .secondary-sidebar-body .settings__nav {
        list-style: none!important;
    }
</style>
