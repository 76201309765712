<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="back-button mt-0">
                            <router-link class="back-button__icon" :to="{name : 'company.setting.integration'}">
                                <i class="eicon e-back"></i>
                            </router-link>
                            <div class="back-button__text">
                                {{ $t(`integrations`) }}
                                <v-popover offset="10" :disabled="false" style="display: initial;">
                                    <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                    <template slot="popover">
                                        <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                        <div style="text-align:left;">
                                            <a v-close-popover
                                               :href="hint.href"
                                               target="_blank">
                                                {{ $t(hint.text) }}
                                            </a>
                                        </div>
                                    </template>
                                </v-popover>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 mb-4">
                            <div class="billing-card details__card gutter-10">
                                <div class="details__card__head">
                                    <img v-if="showMeet" src="/assets/images/gmeet.png" alt=""
                                         style="max-width:170px; max-height:30px;">
                                    <img v-else src="/assets/images/zoom.png" alt=""
                                         style="max-width:170px; max-height:30px;">
                                </div>
                                <div v-if="showMeet" class="form-box">
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label class="text-capitalize">Client ID</label>
                                            <input type="text" maxlength="255" placeholder="Client ID"
                                                   v-model="form.meet.config.client_id" class="form-control"
                                                   id="meet_client_id" ref="meet_client_id">
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label class="text-capitalize">Client secret</label>
                                            <input type="text" maxlength="255" placeholder="Client Secret"
                                                   v-model="form.meet.config.client_secret" class="form-control">
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label class="text-capitalize">Project Id</label>
                                            <input type="text" maxlength="255" placeholder="Project Id"
                                                   v-model="form.meet.config.project_id" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group form-note">
                                        <div class="authorized-link label__full  label__full--primary mt-4">
                                            <p><span class="sub-text">Authorized redirect URIs: </span>    {{ redirectUrl.meet }}</p>
                                            <a href="https://easy.jobs/docs/how-to-configure-google-meet-with-easy-jobs/"
                                                 target="_blank"><span class="custom-link">{{ $t('How to configure google meet?') }}</span></a>
                                        </div>
                                    </div>

                                    <div class="form-group mt-4 d-flex justify-content-between mb-0">
                                        <button type="submit" class="button info-button" @click="updateApp(`meet`)">
                                            {{ $t('update') }}
                                        </button>
                                    </div>
                                </div>
                                <div v-else class="form-box">
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label class="text-capitalize">Client Id</label>
                                            <input type="text" maxlength="255" placeholder="Client Id"
                                                   v-model="form.zoom.config.client_id" class="form-control">
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label class="text-capitalize">Client Secret</label>
                                            <input type="text" maxlength="255" placeholder="Client Secret"
                                                   v-model="form.zoom.config.client_secret" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group form-note">
                                        <div class="authorized-link label__full  label__full--primary mt-4">
                                            <p><span class="sub-text">Set Redirect URL for OAuth: </span>    {{ redirectUrl.zoom }}</p>
                                            <a href="https://easy.jobs/docs/how-to-configure-zoom-app-with-easy-jobs/"
                                               target="_blank"><span class="custom-link">{{ $t('How to configure zoom?') }}</span></a>
                                        </div>
                                    </div>

                                    <div class="form-group mt-4 d-flex justify-content-between mb-0">
                                        <button type="submit" class="button info-button" @click="updateApp(`zoom`)">
                                            {{ $t('update') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import TableLoader from "../../../components/_loaders/_TableLoader";
import {mapState} from "vuex";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
        TableLoader
    },
    data() {
        return {
            form: {
                meet: {
                    app_type: '',
                    config: {
                        client_id: '',
                        project_id: '',
                        client_secret: ''
                    }
                },
                zoom: {
                    app_type: '',
                    config: {
                        client_id: '',
                        client_secret: ''
                    }
                },
            },
            redirectUrl: [],
            submitData: {},
        }
    },
    computed: {
        ...mapState(['company']),
        showMeet() {
            return this.$route.params.type === 'meet';
        },
        hint() {
            if (this.$route.params.type === 'meet') {
                return {
                    text: 'How to configure google meet?',
                    href: 'https://easy.jobs/docs/how-to-configure-google-meet-with-easy-jobs'
                }
            } else {
                return {
                    text: 'How to configure zoom?',
                    href: 'https://easy.jobs/docs/how-to-configure-zoom-app-with-easy-jobs/'
                }
            }
        }
    },
    methods: {
        goBack() {
          return this.$router.push({name:'company.setting.integration'});
        },
        async getUserApps() {
            this.isContentLoading = true;
            try {
                let {data: {data: {zoom, meet, redirect}}} = await client().get('/company/setting/user-app');
                this.form.zoom = zoom;
                this.form.meet = meet;
                this.redirectUrl = redirect;
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        updateApp(appType) {
            this.submitData = this.form[appType];
            if (this.submitData.app_type === '') {
                this.$toast.error(this.$t(`Please select an app type`));
                return false;
            }
            client().post(`/company/setting/user-app/update`, this.submitData).then(({data: {data, message}}) => {
                this.getUserApps();
                this.$toast.success(this.$t(message));
            }).catch(({response: {data}}) => {
                this.$toast.error(this.$t(data.message));
            }).finally(res => {
            });
        },
        canSetupRemoteInterview() {
            if (!this.company.package_rule.remote_interview) {
                this.$toast.error(this.$t(`Remote interview not supported. Please upgrade subscription package.`));
                this.$router.push({
                    name: 'my-account'
                });
            }
        }
    },
    created() {
        this.canSetupRemoteInterview();
        this.getUserApps();
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.meet_client_id.focus();
        });
    }
}
</script>
<style scoped>
.copy-url {
    cursor: pointer;
}

.w-15 {
    width: 15% !important;
}


</style>
